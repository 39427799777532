import React from "react";
import "./App.scss";
import Main from "./containers/Main";
function App() {


  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
